/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFilter = /* GraphQL */ `
  query GetFilter($id: ID!) {
    getFilter(id: $id) {
      id
      phrase
      TTL
      createdAt
      updatedAt
    }
  }
`;
export const listFilters = /* GraphQL */ `
  query ListFilters(
    $filter: ModelFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phrase
        TTL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      telnum
      available
      dayTime
      dayTimePriority
      nightTime
      nightTimePriority
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        telnum
        available
        dayTime
        dayTimePriority
        nightTime
        nightTimePriority
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfig = /* GraphQL */ `
  query GetConfig($key: String!) {
    getConfig(key: $key) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs(
    $key: String
    $filter: ModelConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConfigs(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhrase = /* GraphQL */ `
  query GetPhrase($id: ID!) {
    getPhrase(id: $id) {
      id
      phrase
      createdAt
      updatedAt
    }
  }
`;
export const listPhrases = /* GraphQL */ `
  query ListPhrases(
    $filter: ModelPhraseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhrases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phrase
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMail = /* GraphQL */ `
  query GetMail($id: ID!) {
    getMail(id: $id) {
      id
      title
      message
      procStatus
      verified
      TTL
      createdAt
      updatedAt
    }
  }
`;
export const listMail = /* GraphQL */ `
  query ListMail(
    $filter: ModelMailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMail(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        message
        procStatus
        verified
        TTL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

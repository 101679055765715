import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
  Paper,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";

import PhraseList from "./PhraseList";
import { CreatePhraseUsecase } from "../usecases/PhraseUsecase";
import { phrase } from "../types/PhraseTypes";
import { PhraseForm } from "./PhraseForm";
import { usePhraseContext } from "../context/PhraseContext";
import { SubmitButton } from "./Parts";

const SettingPage = (): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false); // 送信中フラグ
  const [editingPhrase, setEditingPhrase] = useState<phrase>({
    id: "",
    phrase: "",
  });
  const [mutationCount, setMutationCount] = useState<number>(0);
  const { phraseList, reloadPhraseList, isLoadingPhraseList } =
    usePhraseContext();

  const notifyMutation = () => {
    setMutationCount((prev: number) => {
      return prev + 1;
    });
  };

  const initializeStates = () => {
    setEditingPhrase({
      ...editingPhrase,
      id: "",
      phrase: "",
    });
  };

  const handleClickSubmit = async () => {
    if (
      window.confirm(
        "以下の情報を登録します。よろしいですか？\nフィルターメッセージ: " +
          editingPhrase.phrase
      )
    ) {
      setIsSubmitting(true);
      await CreatePhraseUsecase(editingPhrase.phrase)
        .then(() => {
          notifyMutation();
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleClickUpdateWindow = async () => {
    notifyMutation();
  };

  useEffect(() => {
    reloadPhraseList();
    initializeStates();
  }, [mutationCount]);

  return (
    <div>
      <Box sx={{ my: 2 }}>
        <Grid container direction="column" spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="h4">メッセージ設定</Typography>
          </Grid>
          {/* 説明文
          <Grid item xs={4}>
            <Paper
              variant="outlined"
              sx={{
                backgroundColor: "primary.light",
                width: 150,
                px: 2,
                py: 4,
              }}
            >
              フィルター登録時、プルダウンから選択する定型句を編集・削除することができます。
            </Paper>
          </Grid> */}
          <Grid item>
            <Box sx={{ px: 4, pb: 4 }}>
              <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5">メッセージ登録</Typography>
                </Grid>
                <Grid item>
                  <PhraseForm
                    editingPhrase={editingPhrase}
                    setEditingPhrase={setEditingPhrase}
                  />
                </Grid>
                <Grid item>
                  <SubmitButton
                    isProcessing={isSubmitting}
                    handleClick={handleClickSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h4">登録済み一覧</Typography>
              </Grid>
              <Grid item>
                {isLoadingPhraseList ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* 再読み込みボタン */}
                    <Grid item>
                      <IconButton
                        aria-label="updateWindow"
                        color="primary"
                        onClick={handleClickUpdateWindow}
                      >
                        <Typography variant="subtitle1">再読み込み</Typography>
                        <LoopIcon />
                      </IconButton>
                    </Grid>
                    {/* 登録済みリスト */}
                    {phraseList.length > 0 ? (
                      <div>
                        <Grid item>
                          <PhraseList
                            phraseList={phraseList}
                            notifyMutation={notifyMutation}
                          />
                        </Grid>
                      </div>
                    ) : (
                      <Typography variant="h6">
                        登録されているメッセージはありません。
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SettingPage;

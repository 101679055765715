import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Slide,
  useScrollTrigger,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Drawer,
  IconButton,
  Theme,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LockIcon from "@mui/icons-material/Lock";
import PersonAdd from "@mui/icons-material/PersonAdd";
import ManageAccounts from "@mui/icons-material/ManageAccounts";
import ImportExport from "@mui/icons-material/ImportExport";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { Auth } from "aws-amplify";
import "./Header.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  items: {
    flexGrow: 1,
  },
  listIcon: {
    flexGrow: 1,
    textAlign: "left",
  },
  signOutIcon: {
    flexGrow: 1,
    textAlign: "right",
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
}));
const Header = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const menuItems: {
    title: string;
    path: string;
    icon: () => JSX.Element;
    child?: { subTitle: string; subPath: string; subIcon: () => JSX.Element }[];
  }[] = [
    {
      title: "ホーム",
      path: "/",
      icon: () => {
        return <HomeIcon />;
      },
    },
    {
      title: "連絡先設定",
      path: "/user",
      icon: () => {
        return <PeopleAltIcon />;
      },
      child: [
        {
          subTitle: "連絡先登録",
          subPath: "/user/create",
          subIcon: () => {
            return <PersonAdd />;
          },
        },
        {
          subTitle: "連絡先一覧",
          subPath: "/user/list",
          subIcon: () => {
            return <ManageAccounts />;
          },
        },
        {
          subTitle: "優先度変更",
          subPath: "/user/sort",
          subIcon: () => {
            return <ImportExport />;
          },
        },
      ],
    },
    {
      title: "メッセージ設定",
      path: "/settings",
      icon: () => {
        return <EditIcon />;
      },
    },
    // {
    //   title: "アカウント設定",
    //   path: "/create",
    //   icon: () => {
    //     return <LockIcon />;
    //   },
    // },
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const trigger = useScrollTrigger();
  const [userOpen, setUserOpen] = useState(false);
  const handleClick = () => {
    setUserOpen(!userOpen);
  };

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
    setUserOpen(false);
  };

  // メニューリスト
  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      // onClick={() => toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item, index) =>
          !item.child ? (
            <ListItem
              button
              key={item.title}
              onClick={() => {
                toggleDrawer(false);
                history.push(item.path);
              }}
            >
              <ListItemIcon>{item.icon()}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ) : (
            <span>
              <ListItem
                button
                key={item.title}
                onClick={() => {
                  handleClick();
                }}
              >
                <ListItemIcon>{item.icon()}</ListItemIcon>
                <ListItemText primary={item.title} />
                {userOpen?<ExpandMoreIcon />:<ExpandLess />}
              </ListItem>
              {item.child.map((subItem) => (
                <Collapse in={userOpen} timeout="auto" unmountOnExit>
                  <ListItem
                    button
                    key={subItem.subTitle}
                    onClick={() => {
                      toggleDrawer(false);
                      history.push(subItem.subPath);
                    }}
                    sx={{ marginLeft: "2vw" }}
                  >
                    <ListItemIcon>{subItem.subIcon()}</ListItemIcon>
                    <ListItemText primary={subItem.subTitle} />
                  </ListItem>
                </Collapse>
              ))}
            </span>
          )
        )}
      </List>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={1}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => toggleDrawer(true)}
                  edge="start"
                  sx={{ mr: 2, ...(isDrawerOpen && { display: "none" }) }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>

              <Grid item xs={10}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <img id="icon" alt="Logo" src="./logo.svg" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      sx={{
                        flexGrow: 1,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                      className="style"
                    >
                      T-Call
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1} sx={{ textAlign: "right" }}>
                <IconButton
                  edge="start"
                  // className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => Auth.signOut()}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
          {/* メニュー一覧 */}
          <Drawer open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
            {list()}
          </Drawer>
        </AppBar>
      </Slide>
    </Box>
  );
};

export default Header;

import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { AuthState } from "@aws-amplify/ui-components";

import FilterList from "./FilterList";
import {
  ListFiltersUsecase,
} from "../usecases/FilterUsecase";
import { filter } from "../types/FilterTypes";
import { dateToTtl } from "../utils/UtilFunctions";
import SubmitBox from "./SubmitBox";
import NotificationSwitchBox from "./NotificationSwitchBox";
import { useCognitoAuthContext } from "../context/CognitoAuthContext";

const SubmitPage = (): JSX.Element => {
  const [editingFilter, setEditingFilter] = useState<filter>({
    id: "",
    phrase: "",
    TTL: dateToTtl(new Date()),
  });
  const [filterList, setFilterList] = useState<filter[]>([]); // 登録済みリスト
  const [isLoading, setIsLoading] = useState(false);
  const [mutationCount, setMutationCount] = useState<number>(0);

  const { authState } = useCognitoAuthContext();

  const notifyMutation = () => {
    setMutationCount((prev: number) => {
      return prev + 1;
    });
  };

  const initializeStates = () => {
    setEditingFilter({
      ...editingFilter,
      id: "",
      phrase: "",
      TTL: dateToTtl(new Date()),
    });
  };

  const handleClickUpdateWindow = async () => {
    notifyMutation();
  };

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      Promise.all([
        // 個別設定の一覧取得
        new Promise(() => {
          setIsLoading(true);
          ListFiltersUsecase()
            .then((res) => {
              setFilterList(
                res.sort((left, right) => {
                  if (left.TTL && right.TTL) {
                    // nullチェック
                    return left.TTL - right.TTL;
                  } else {
                    return 0;
                  }
                })
              );
            })
            .then(() => {
              initializeStates(); // 編集中の内容を初期化
            })
            .catch((err) => {
              console.log(err);
              alert(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }),
      ]).catch((err) => {
        alert(err);
      });
    }
  }, [mutationCount, authState]);

  return (
    <div>
      <Box sx={{ my: 2 }}>
        <Grid container direction="column" spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="h4">アラートコール設定</Typography>
          </Grid>
          <Grid item>
            <Box sx={{ px: 4, pb: 4 }}>
              <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <NotificationSwitchBox />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ px: 4, pb: 4 }}>
              <SubmitBox notifyMutation={notifyMutation} />
            </Box>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h4">登録済み一覧</Typography>
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* 再読み込みボタン */}
                    <Grid item>
                      <IconButton
                        aria-label="updateWindow"
                        color="primary"
                        onClick={handleClickUpdateWindow}
                      >
                        <Typography variant="subtitle1">再読み込み</Typography>
                        <LoopIcon />
                      </IconButton>
                    </Grid>
                    {/* 登録済みリスト */}
                    {filterList.length > 0 ? (
                      <div>
                        <Grid item>
                          <FilterList
                            filterList={filterList}
                            notifyMutation={notifyMutation}
                          />
                        </Grid>
                      </div>
                    ) : (
                      <Typography variant="h6">
                        登録されているデータはありません。
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SubmitPage;

import {
  CreateConfig,
  ListConfigs,
  UpdateConfig,
  DeleteConfig,
} from "../services/ConfigService";
import { config } from "../types/ConfigTypes";

// Configテーブルをオブジェクトに変換
export const GetConfigObjectUsecase = async (): Promise<{
  [key: string]: any;
}> => {
  try {
    const configs = await ListConfigs();
    const configObj = Object.fromEntries(
      configs.map((config) => {
        return [config.key, config.value];
      })
    ); // 列挙可能なオブジェクトを単一オブジェクトのプロパティに展開
    return configObj;
  } catch (err) {
    console.log(err);
    throw new Error("コンフィグオブジェクト作成中にエラーが発生しました");
  }
};

export const CreateConfigUsecase = async (
  key: string,
  value: any
): Promise<config> => {
  try {
    if (key !== "") {
      const response = await CreateConfig(key, value);
      return response;
    } else {
      throw new Error("キーが未入力です");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const ListConfigsUsecase = async (): Promise<config[]> => {
  try {
    const configs = await ListConfigs();
    return configs;
  } catch (err) {
    console.log(err);
    throw new Error("コンフィグ一覧の取得中にエラーが発生しました");
  }
};

export const UpdateConfigUsecase = async (
  key: string,
  value: any
): Promise<config> => {
  try {
    if (key === "") {
      throw new Error("キーが未入力です");
    }
    if (value === undefined || value === null) { // "!value"だとboolean使えなくなる
      throw new Error("値が不正です");
    }
    const response = await UpdateConfig(key, value);
    return response;
  } catch (err) {
    console.log(err);
    throw new Error("コンフィグ更新中にエラーが発生しました");
  }
};

export const DeleteConfigUsecase = async (key: string): Promise<void> => {
  try {
    const config = await DeleteConfig(key);
    return config;
  } catch (err) {
    console.log(err);
    throw new Error("コンフィグの削除に失敗しました");
  }
};

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { filter } from "../types/FilterTypes";
import {
  DeleteFilterUsecase,
  UpdateFilterUsecase,
} from "../usecases/FilterUsecase";
import { FilterForm } from "./FilterForm";
import { ttlToReadableString } from "../utils/UtilFunctions";

// 編集ダイアログ
const EditDialog = (props: {
  filter: filter;
  open: boolean;
  onClose: () => void;
  notifyMutation: () => void;
}): JSX.Element => {
  const [editingFilter, setEditingFilter] = useState<filter>({
    ...props.filter,
  });
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const handleClose = () => {
    // 内部の値を初期化
    setEditingFilter({ ...props.filter });
    setIsConfirming(false);
    // 渡されたクローズ処理を実行
    props.onClose();
  };

  const handleEditUpdate = () => {
    setIsConfirming(true);
  };
  const handleEditUpdateConfirm = () => {
    UpdateFilterUsecase(
      editingFilter.id,
      editingFilter.phrase,
      editingFilter.TTL
    )
      .then((res) => {
        props.notifyMutation();
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isConfirming ? (
          <div>
            <DialogTitle id="alert-dialog-title">
              以下の内容で更新してよろしいですか？
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                メッセージ: {editingFilter.phrase}
                <br />
                停止期限:{" "}
                {editingFilter.TTL
                  ? ttlToReadableString(editingFilter.TTL)
                  : "指定なし"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditUpdateConfirm}>送信</Button>
              <Button onClick={handleClose} autoFocus>
                キャンセル
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">編集</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <FilterForm
                  editingFilter={editingFilter}
                  setEditingFilter={setEditingFilter}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditUpdate}>更新</Button>
              <Button onClick={handleClose} autoFocus>
                キャンセル
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

// 削除ダイアログ
const DeleteDialog = (props: {
  filter: filter;
  open: boolean;
  onClose: () => void;
  notifyMutation: () => void;
}): JSX.Element => {
  const handleDeleteContinue = () => {
    DeleteFilterUsecase(props.filter.id)
      .then((res) => {
        props.onClose();
      })
      .then(() => {
        props.notifyMutation();
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          削除してよろしいですか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            メッセージ: {props.filter.phrase}
            <br />
            停止期限:{" "}
            {props.filter.TTL ? ttlToReadableString(props.filter.TTL) : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteContinue}>削除</Button>
          <Button onClick={props.onClose} autoFocus>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const FilterCard = (props: {
  filter: filter;
  notifyMutation: () => void;
}): JSX.Element => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false); // 編集ダイアログ開いているかフラグ
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // 削除ダイアログ開いているかフラグ

  // [編集]クリック時
  const onClickEdit = () => {
    setIsEditDialogOpen(true);
  };

  // 編集ダイアログ閉じる
  const handleCloseEdit = () => {
    setIsEditDialogOpen(false);
  };

  // [削除]クリック時
  const onClickDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  // 削除ダイアログ閉じる
  const handleCloseDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  let untilWhenString = "";
  if (props.filter.TTL) {
    untilWhenString = ttlToReadableString(props.filter.TTL);
  } else {
    untilWhenString = "期間指定なし";
  }

  return (
    <Card sx={{ minWidth: 275, mt: 3 }} elevation={4}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
          {"「"}
          <Typography
            component="span"
            sx={{ color: "red", fontWeight: "bold" }}
          >
            {props.filter.phrase}
          </Typography>
          {"」をタイトルに含むアラートメールの通知は、"}
          <Typography
            component="span"
            sx={{ color: "red", fontWeight: "bold" }}
          >
            {untilWhenString}
          </Typography>
          {"まで停止されます。"}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onClickEdit}>
          編集
        </Button>
        <EditDialog
          filter={props.filter}
          open={isEditDialogOpen}
          onClose={handleCloseEdit}
          notifyMutation={props.notifyMutation}
        />
        <Button
          size="small"
          onClick={onClickDelete}
          sx={{ color: "secondary" }}
        >
          削除
        </Button>
        <DeleteDialog
          filter={props.filter}
          open={isDeleteDialogOpen}
          onClose={handleCloseDelete}
          notifyMutation={props.notifyMutation}
        />
      </CardActions>
    </Card>
  );
};

const FilterList = (props: {
  filterList: filter[];
  notifyMutation: () => void;
}): JSX.Element => {
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {props.filterList.map((filter, idx) => {
        return (
          <FilterCard
            key={idx}
            filter={filter}
            notifyMutation={props.notifyMutation}
          />
        );
      })}
    </Box>
  );
};

export default FilterList;

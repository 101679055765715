import { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Paper,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { CreateFilterUsecase } from "../usecases/FilterUsecase";
import { filter } from "../types/FilterTypes";
import { dateToTtl, ttlToReadableString } from "../utils/UtilFunctions";
import { FilterForm } from "./FilterForm";
import { SubmitButton } from "./Parts";

// 送信確認ダイアログ
const ConfirmationDialog = (props: {
  editingFilter: filter; // 登録しようとしている内容
  open: boolean;
  onClose: () => void;
  executeMutation: () => void; // 「確定」を押したときに親コンポーネントで実行してもらうコンフィグ更新処理
  notifyMutation: () => void;
}): JSX.Element => {
  const { editingFilter, open, onClose, executeMutation } = props;

  const handleConfirmCreateFilter = () => {
    onClose(); // 確定押したらダイアログはすぐ閉じる
    executeMutation(); // 親元でUpdate処理
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          以下の内容を登録してよろしいですか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            メッセージ: {editingFilter.phrase}
            <br />
            停止期限:{" "}
            {editingFilter.TTL
              ? ttlToReadableString(editingFilter.TTL)
              : "指定なし"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmCreateFilter}>送信</Button>
          <Button onClick={onClose} autoFocus>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const SubmitBox = (props: { notifyMutation: () => void }): JSX.Element => {
  const { notifyMutation } = props;
  const [isSubmitting, setIsSubmitting] = useState(false); // 個別設定の送信中フラグ
  const [editingFilter, setEditingFilter] = useState<filter>({
    id: "",
    phrase: "",
    TTL: dateToTtl(new Date()),
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false); // 確認ダイアログ開いているかフラグ

  const executeCreateFilter = async () => {
    setIsSubmitting(true);
    await CreateFilterUsecase(editingFilter.phrase, editingFilter.TTL)
      .then(() => {
        notifyMutation();
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Paper sx={{ p: 4 }} elevation={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">個別設定</Typography>
        </Grid>
        <Grid item>
          <Paper
            elevation={2}
            sx={{
              backgroundColor: "#FFEEFF",
              p: 2,
              mb: 3,
              maxWidth: 240,
            }}
          >
            タイトルに指定した単語を含むアラートメールの通知を、指定の日時まで無効化します。
            <Typography component="span" sx={{ fontWeight: "bold" }}>
              メールの本文は対象となりません
            </Typography>
            ので、ご注意ください。
            <br />
          </Paper>
        </Grid>
        {/* 入力フォーム */}
        <Grid item>
          <FilterForm
            editingFilter={editingFilter}
            setEditingFilter={setEditingFilter}
          />
        </Grid>
        {/* 横線 */}
        <Grid item>
          <Divider variant="middle" sx={{ width: 200 }} />
        </Grid>
        {/* 送信ボタン */}
        <Grid item>
          <SubmitButton
            isProcessing={isSubmitting}
            handleClick={handleOpenDialog}
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={isDialogOpen}
        editingFilter={editingFilter}
        onClose={handleCloseDialog}
        executeMutation={executeCreateFilter}
        notifyMutation={props.notifyMutation}
      />
    </Paper>
  );
};

export default SubmitBox;

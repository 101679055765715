import { API, graphqlOperation } from "aws-amplify";
import {
  CreateUserInput,
  CreateUserMutation,
  ListUsersQuery,
  ListUsersQueryVariables,
  UpdateUserInput,
  UpdateUserMutation,
  DeleteUserInput,
  DeleteUserMutation,
  ModelUserFilterInput,
} from "../API";
import { createUser, updateUser, deleteUser } from "../graphql/mutations";
import { listUsers } from "../graphql/queries";
import { user } from "../types/UserTypes";

export const CreateUser = async (
  name: string,
  telnum: string,
  available: boolean,
  dayTime: boolean,
  dayTimePriority: number,
  nightTime: boolean,
  nightTimePriority: number
): Promise<user> => {
  try {
    const params: CreateUserInput = {
      name: name,
      telnum: telnum,
      available: available,
      dayTime: dayTime,
      dayTimePriority: dayTimePriority,
      nightTime: nightTime,
      nightTimePriority: nightTimePriority,
    };
    const response = (await API.graphql(
      graphqlOperation(createUser, { input: params })
    )) as { data: CreateUserMutation };
    if (response.data && response.data.createUser) {
      const datas: user = {
        id: response.data.createUser.id,
        name: response.data.createUser.name,
        telnum: response.data.createUser.telnum,
        available: response.data.createUser.available,
        dayTime: response.data.createUser.dayTime,
        dayTimePriority: response.data.createUser.dayTimePriority,
        nightTime: response.data.createUser.nightTime,
        nightTimePriority: response.data.createUser.nightTimePriority,
      };
      return datas;
    } else {
      throw new Error("return noData");
    }
  } catch (err) {
    throw "ユーザー登録中にエラーが発生しました";
  }
};

export const ListUsers = async (
  filter?: ModelUserFilterInput
): Promise<user[]> => {
  let nextToken: string | null = null;
  const datas: user[] = [];
  try {
    //未取得データがある場合は全てのデータを取得するまでループ
    do {
      const params: ListUsersQueryVariables = {
        limit: 100,
        nextToken: nextToken,
      };
      if (filter) {
        params.filter = filter;
      }
      const response = (await API.graphql(
        graphqlOperation(listUsers, params)
      )) as {
        data: ListUsersQuery;
      };
      if (response.data.listUsers && response.data.listUsers.items) {
        response.data.listUsers.items.map((item) => {
          if (item) {
            datas.push({
              id: item.id,
              name: item.name,
              telnum: item.telnum,
              available: item.available,
              dayTime: item.dayTime,
              dayTimePriority: item.dayTimePriority,
              nightTime: item.nightTime,
              nightTimePriority: item.nightTimePriority,
            } as user);
          }
        });
        nextToken = response.data.listUsers.nextToken;
      }
    } while (nextToken !== null);
  } catch (err) {
    console.log(err);
    throw err;
  }
  return datas;
};

export const UpdateUser = async (
  id: string,
  name: string | null,
  telnum: string | null,
  available: boolean | null,
  dayTime: boolean | null,
  dayTimePriority: number | null,
  nightTime: boolean | null,
  nightTimePriority: number | null
): Promise<user> => {
  try {
    const params: UpdateUserInput = {
      id: id,
      name: name,
      telnum: telnum,
      available: available,
      dayTime: dayTime,
      dayTimePriority: dayTimePriority,
      nightTime: nightTime,
      nightTimePriority: nightTimePriority,
    };
    const response = (await API.graphql(
      graphqlOperation(updateUser, { input: params })
    )) as { data: UpdateUserMutation };
    if (response.data.updateUser) {
      const data: user = {
        id: response.data.updateUser.id,
        name: response.data.updateUser.name,
        telnum: response.data.updateUser.telnum,
        available: response.data.updateUser.available,
        dayTime: response.data.updateUser.dayTime,
        dayTimePriority: response.data.updateUser.dayTimePriority,
        nightTime: response.data.updateUser.nightTime,
        nightTimePriority: response.data.updateUser.nightTimePriority,
      };
      return data;
    } else {
      throw new Error("no Data");
    }
  } catch (err) {
    throw "ユーザー更新中にエラーが発生しました";
  }
};

export const DeleteUser = async (id: string): Promise<void> => {
  try {
    const params: DeleteUserInput = {
      id: id,
    };
    const response = (await API.graphql(
      graphqlOperation(deleteUser, { input: params })
    )) as { data: DeleteUserMutation };

    if (!response.data.deleteUser) {
      throw new Error("no Data");
    }
  } catch (err) {
    throw "ユーザー削除中にエラーが発生しました";
  }
};

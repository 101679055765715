import React, { useState, createContext, useContext, useEffect } from "react";
import { AuthState } from "@aws-amplify/ui-components";

// サインイン状態をグローバルに管理するコンテクスト

interface ContextInterface {
  authState: AuthState;
  handleAuthStateChange: (
    nextAuthState: React.SetStateAction<AuthState>,
    authData: any
  ) => void;
}

const CognitoAuthContext = createContext<ContextInterface | undefined>(
  undefined
);
export const useCognitoAuthContext = () => {
  const context = useContext(CognitoAuthContext);

  if (context === undefined) {
    throw new Error(
      "useCognitoAuthContext must be used within a CognitoAuthContextProvider."
    );
  }
  return context;
};

export const CognitoAuthContextProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.SignOut);
  const handleAuthStateChange = (
    nextAuthState: React.SetStateAction<AuthState>,
    authData: any
  ) => {
    setAuthState(nextAuthState);
  };

  return (
    <CognitoAuthContext.Provider value={{ authState, handleAuthStateChange }}>
      {props.children}
    </CognitoAuthContext.Provider>
  );
};

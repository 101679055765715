import { Button } from "@mui/material";

export const SubmitButton = (props: {
  isProcessing: boolean; // 処理中フラグ true=処理中
  disabled?: boolean; // 無効化フラグ
  processingMessage?: string; // 処理中に表示する文言
  submitMessage?: string; // 処理中でない時に表示する文言
  handleClick: () => void; // クリック時にさせたい処理
}): JSX.Element => {
  return (
    <div>
      {props.isProcessing ? (
        // 処理中ボタン
        <Button variant="contained" color="primary">
          {props.processingMessage ? props.processingMessage : "送信中..."}
        </Button>
      ) : (
        // 処理中でないボタン
        <Button
          disabled={props.disabled}
          variant="outlined"
          color="primary"
          onClick={props.handleClick}
        >
          {props.submitMessage ? props.submitMessage : "送信"}
        </Button>
      )}
    </div>
  );
};

import React from "react";
// import Amplify, { Auth } from "@aws-amplify/auth";
import Amplify, { Auth } from "aws-amplify";
import {
  withAuthenticator,
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import {
  createTheme,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@mui/material";
import awsconfig from "./aws-exports";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { blue, lightBlue } from "@mui/material/colors";

import "./App.css";
import SubmitPage from "./component/SubmitPage";
import SettingPage from "./component/SettingPage";
import UserPage from "./component/UserPage";
import Header from "./component/Header";
import CreateUserPage from "./component/CreateUserPage";
import { useCognitoAuthContext } from "./context/CognitoAuthContext";
import { PhraseContextProvider } from "./context/PhraseContext";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: lightBlue[500],
    },
  },
});

// Amplifyの設定を行う
Amplify.configure({
  ...awsconfig,
  Auth: {
    region: "ap-northeast-1",
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,
  },
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});

function App() {
  const { handleAuthStateChange } = useCognitoAuthContext();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <AmplifyAuthenticator handleAuthStateChange={handleAuthStateChange}>
          <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
          <Router>
            <PhraseContextProvider>
              <Header />
              <Grid container className="body">
                <Grid item xs={12}>
                  <Route exact path="/" component={SubmitPage} />
                  <Route exact path="/settings" component={SettingPage} />
                  <Route exact path="/user/:module" component={UserPage} />
                  {/* <Route exact path="/create" component={CreateUserPage} /> */}
                </Grid>
              </Grid>
            </PhraseContextProvider>
          </Router>
        </AmplifyAuthenticator>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;

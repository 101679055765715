import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { phrase } from "../types/PhraseTypes";
import {
  DeletePhraseUsecase,
  UpdatePhraseUsecase,
} from "../usecases/PhraseUsecase";
import { PhraseForm } from "./PhraseForm";

// 編集ダイアログ
const EditDialog = (props: {
  phrase: phrase;
  open: boolean;
  onClose: () => void;
  notifyMutation: () => void;
}): JSX.Element => {
  const [editingPhrase, setEditingPhrase] = useState<phrase>({
    ...props.phrase,
  });
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const handleClose = () => {
    // 内部の値を初期化
    setEditingPhrase({ ...props.phrase });
    setIsConfirming(false);
    // 渡されたクローズ処理を実行
    props.onClose();
  };

  const handleEditUpdate = () => {
    setIsConfirming(true);
  };
  const handleEditUpdateConfirm = () => {
    UpdatePhraseUsecase(
      editingPhrase.id,
      editingPhrase.phrase,
    )
      .then((res) => {
        props.notifyMutation();
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isConfirming ? (
          <div>
            <DialogTitle id="alert-dialog-title">
              以下の内容で更新してよろしいですか？
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                メッセージ: {editingPhrase.phrase}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditUpdateConfirm}>送信</Button>
              <Button onClick={handleClose} autoFocus>
                キャンセル
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">編集</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <PhraseForm
                  editingPhrase={editingPhrase}
                  setEditingPhrase={setEditingPhrase}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditUpdate}>更新</Button>
              <Button onClick={handleClose} autoFocus>
                キャンセル
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

// 削除ダイアログ
const DeleteDialog = (props: {
  phrase: phrase;
  open: boolean;
  onClose: () => void;
  notifyMutation: () => void;
}): JSX.Element => {
  const handleDeleteContinue = () => {
    DeletePhraseUsecase(props.phrase.id)
      .then((res) => {
        props.onClose();
      })
      .then(() => {
        props.notifyMutation();
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          削除してよろしいですか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            メッセージ: {props.phrase.phrase}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteContinue}>削除</Button>
          <Button onClick={props.onClose} autoFocus>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const MessageCard = (props: {
  phrase: phrase;
  notifyMutation: () => void;
}): JSX.Element => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false); // 編集ダイアログ開いているかフラグ
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // 削除ダイアログ開いているかフラグ

  // [編集]クリック時
  const onClickEdit = () => {
    setIsEditDialogOpen(true);
  };

  // 編集ダイアログ閉じる
  const handleCloseEdit = () => {
    setIsEditDialogOpen(false);
  };

  // [削除]クリック時
  const onClickDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  // 削除ダイアログ閉じる
  const handleCloseDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Card sx={{ minWidth: 275, marginBottom: 4 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          フィルターメッセージ
        </Typography>
        <Typography variant="h5" component="div">
          {props.phrase.phrase}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onClickEdit}>
          編集
        </Button>
        <EditDialog
          phrase={props.phrase}
          open={isEditDialogOpen}
          onClose={handleCloseEdit}
          notifyMutation={props.notifyMutation}
        />
        <Button
          size="small"
          onClick={onClickDelete}
          sx={{ color: "secondary" }}
        >
          削除
        </Button>
        <DeleteDialog
          phrase={props.phrase}
          open={isDeleteDialogOpen}
          onClose={handleCloseDelete}
          notifyMutation={props.notifyMutation}
        />
      </CardActions>
    </Card>
  );
};

const PhraseList = (props: {
  phraseList: phrase[];
  notifyMutation: () => void;
}): JSX.Element => {
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {props.phraseList.map((phrase) => {
        return (
          <MessageCard phrase={phrase} notifyMutation={props.notifyMutation} />
        );
      })}
    </Box>
  );
};

export default PhraseList;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFilter = /* GraphQL */ `
  mutation CreateFilter(
    $input: CreateFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    createFilter(input: $input, condition: $condition) {
      id
      phrase
      TTL
      createdAt
      updatedAt
    }
  }
`;
export const updateFilter = /* GraphQL */ `
  mutation UpdateFilter(
    $input: UpdateFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    updateFilter(input: $input, condition: $condition) {
      id
      phrase
      TTL
      createdAt
      updatedAt
    }
  }
`;
export const deleteFilter = /* GraphQL */ `
  mutation DeleteFilter(
    $input: DeleteFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    deleteFilter(input: $input, condition: $condition) {
      id
      phrase
      TTL
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      telnum
      available
      dayTime
      dayTimePriority
      nightTime
      nightTimePriority
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      telnum
      available
      dayTime
      dayTimePriority
      nightTime
      nightTimePriority
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      telnum
      available
      dayTime
      dayTimePriority
      nightTime
      nightTimePriority
      createdAt
      updatedAt
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig(
    $input: CreateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    createConfig(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig(
    $input: UpdateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    updateConfig(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig(
    $input: DeleteConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    deleteConfig(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const createPhrase = /* GraphQL */ `
  mutation CreatePhrase(
    $input: CreatePhraseInput!
    $condition: ModelPhraseConditionInput
  ) {
    createPhrase(input: $input, condition: $condition) {
      id
      phrase
      createdAt
      updatedAt
    }
  }
`;
export const updatePhrase = /* GraphQL */ `
  mutation UpdatePhrase(
    $input: UpdatePhraseInput!
    $condition: ModelPhraseConditionInput
  ) {
    updatePhrase(input: $input, condition: $condition) {
      id
      phrase
      createdAt
      updatedAt
    }
  }
`;
export const deletePhrase = /* GraphQL */ `
  mutation DeletePhrase(
    $input: DeletePhraseInput!
    $condition: ModelPhraseConditionInput
  ) {
    deletePhrase(input: $input, condition: $condition) {
      id
      phrase
      createdAt
      updatedAt
    }
  }
`;
export const createMail = /* GraphQL */ `
  mutation CreateMail(
    $input: CreateMailInput!
    $condition: ModelMailConditionInput
  ) {
    createMail(input: $input, condition: $condition) {
      id
      title
      message
      procStatus
      verified
      TTL
      createdAt
      updatedAt
    }
  }
`;
export const updateMail = /* GraphQL */ `
  mutation UpdateMail(
    $input: UpdateMailInput!
    $condition: ModelMailConditionInput
  ) {
    updateMail(input: $input, condition: $condition) {
      id
      title
      message
      procStatus
      verified
      TTL
      createdAt
      updatedAt
    }
  }
`;
export const deleteMail = /* GraphQL */ `
  mutation DeleteMail(
    $input: DeleteMailInput!
    $condition: ModelMailConditionInput
  ) {
    deleteMail(input: $input, condition: $condition) {
      id
      title
      message
      procStatus
      verified
      TTL
      createdAt
      updatedAt
    }
  }
`;

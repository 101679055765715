import { API, graphqlOperation } from "aws-amplify";
import {
  CreatePhraseInput,
  CreatePhraseMutation,
  ListPhrasesQuery,
  ListPhrasesQueryVariables,
  UpdatePhraseInput,
  UpdatePhraseMutation,
  DeletePhraseInput,
  DeletePhraseMutation,
} from "../API";
import { createPhrase, updatePhrase, deletePhrase } from "../graphql/mutations";
import { listPhrases } from "../graphql/queries";
import { phrase } from "../types/PhraseTypes";

export const CreatePhrase = async (
  phrase: string,
): Promise<phrase> => {
  try {
    const params: CreatePhraseInput = {
      phrase: phrase,
    };
    const response = (await API.graphql(
      graphqlOperation(createPhrase, { input: params })
    )) as { data: CreatePhraseMutation };
    if (response.data && response.data.createPhrase) {
      const datas: phrase = {
        id: response.data.createPhrase.id,
        phrase: response.data.createPhrase.phrase,
      };
      return datas;
    } else {
      throw new Error("return noData");
    }
  } catch (err) {
    console.log(err);
    throw new Error("フィルター定型文登録中にエラーが発生しました");
  }
};

export const ListPhrases = async (): Promise<phrase[]> => {
  let nextToken: string | null = null;
  const datas: phrase[] = [];
  try {
    //未取得データがある場合は全てのデータを取得するまでループ
    do {
      const params: ListPhrasesQueryVariables = {
        limit: 100,
        nextToken: nextToken,
      };
      const response = (await API.graphql(
        graphqlOperation(listPhrases, params)
      )) as {
        data: ListPhrasesQuery;
      };
      if (response.data.listPhrases && response.data.listPhrases.items) {
        response.data.listPhrases.items.map((item) => {
          if (item) {
            datas.push({
              id: item.id,
              phrase: item.phrase,
            } as phrase);
          }
        });
        nextToken = response.data.listPhrases.nextToken;
      }
    } while (nextToken !== null);
  } catch (err) {
    console.log(err);
    throw new Error("フィルターメッセージ定型文一覧を取得中にエラーが発生しました。");
  }
  return datas;
};

export const UpdatePhrase = async (
  id: string,
  phrase: string,
): Promise<phrase> => {
  try {
    const params: UpdatePhraseInput = {
      id: id,
      phrase: phrase,
    };
    const response = (await API.graphql(
      graphqlOperation(updatePhrase, { input: params })
    )) as { data: UpdatePhraseMutation };
    if (response.data.updatePhrase) {
      const data: phrase = {
        id: response.data.updatePhrase.id,
        phrase: response.data.updatePhrase.phrase,
      };
      return data;
    } else {
      throw new Error("no Data");
    }
  } catch (err) {
    console.log(err);
    throw new Error("フィルター定型文更新中にエラーが発生しました");
  }
};

export const DeletePhrase = async (id: string): Promise<void> => {
  try {
    const params: DeletePhraseInput = {
      id: id,
    };
    const response = (await API.graphql(
      graphqlOperation(deletePhrase, { input: params })
    )) as { data: DeletePhraseMutation };

    if (!response.data.deletePhrase) {
      throw new Error("no Data");
    }
  } catch (err) {
    console.log(err);
    throw new Error("フィルター定型文削除中にエラーが発生しました");
  }
};

import {
  CreatePhrase,
  ListPhrases,
  UpdatePhrase,
  DeletePhrase,
} from "../services/PhraseService";
import { phrase } from "../types/PhraseTypes";

export const CreatePhraseUsecase = async (
  phrase: string,
): Promise<phrase> => {
  try {
    if (phrase !== "") {
      const response = await CreatePhrase(phrase);
      return response;
    } else {
      throw new Error("フィルター定型文が未入力です");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const ListPhrasesUsecase = async (): Promise<phrase[]> => {
  try {
    const phrases = await ListPhrases();
    return phrases;
  } catch (err) {
    console.log(err);
    throw new Error("フィルター定型文一覧の取得中にエラーが発生しました");
  }
};

export const UpdatePhraseUsecase = async (
  id: string,
  phrase: string,
): Promise<phrase> => {
  try {
    if (phrase === "") {
      throw new Error("フィルター定型文が未入力です");
    }
    if (id === "") {
      throw new Error("IDが未入力です");
    }
    const response = await UpdatePhrase(id, phrase);
    return response;
  } catch (err) {
    console.log(err);
    throw new Error("フィルター定型文更新中にエラーが発生しました");
  }
};

export const DeletePhraseUsecase = async (id: string): Promise<void> => {
  try {
    const phrase = await DeletePhrase(id);
    return phrase;
  } catch (err) {
    console.log(err);
    throw new Error("フィルター定型文の削除に失敗しました");
  }
};

import {
  CreateFilter,
  ListFilters,
  UpdateFilter,
  DeleteFilter,
} from "../services/FilterService";
import { filter } from "../types/FilterTypes";

export const CreateFilterUsecase = async (
  phrase: string,
  TTL: number | null
): Promise<filter> => {
  try {
    if (phrase !== "") {
      const response = await CreateFilter(phrase, TTL);
      return response;
    } else {
      throw new Error("フィルターメッセージが未入力です");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const ListFiltersUsecase = async (): Promise<filter[]> => {
  try {
    const filters = await ListFilters();
    return filters;
  } catch (err) {
    console.log(err);
    throw new Error("フィルターメッセージ一覧の取得中にエラーが発生しました");
  }
};

export const UpdateFilterUsecase = async (
  id: string,
  phrase: string,
  TTL: number | null
): Promise<filter> => {
  try {
    if (phrase === "") {
      throw new Error("フィルターメッセージが未入力です");
    }
    if (id === "") {
      throw new Error("IDが未入力です");
    }
    // if (typeof(TTL)!=="number" && TTL !==null) { どういう意図のチェック？
    // if (!TTL) {
    //   throw "停止期限が不正です";
    // }
    const response = await UpdateFilter(id, phrase, TTL);
    return response;
  } catch (err) {
    console.log(err);
    throw new Error("フィルターメッセージ更新中にエラーが発生しました");
  }
};

export const DeleteFilterUsecase = async (id: string): Promise<void> => {
  try {
    const filter = await DeleteFilter(id);
    return filter;
  } catch (err) {
    console.log(err);
    throw new Error("フィルターメッセージの削除に失敗しました");
  }
};

import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";

import { CreateUserUsecase } from "../usecases/UserUsecase";
import { user } from "../types/UserTypes";
import {
  ttlToReadableString,
  addCountryCode,
  removeCountryCode,
} from "../utils/UtilFunctions";
import { SubmitButton } from "./Parts";

interface editingUser extends user {
  // ユーザーがフォームで入力した電話番号のオリジナル文字列
  // telnumプロパティは変換後（"+81"始まり）の文字列が入る
  originalTextTelnum: string;
}

// 送信確認ダイアログ
const ConfirmationDialog = (props: {
  editingUser: editingUser; // 登録しようとしている内容
  open: boolean;
  onClose: () => void;
  executeMutation: () => void; // 「確定」を押したときに親コンポーネントで実行してもらうコンフィグ更新処理
  notifyMutation: () => void;
}): JSX.Element => {
  const { editingUser, open, onClose, executeMutation } = props;

  const handleConfirmCreateUser = () => {
    onClose(); // 確定押したらダイアログはすぐ閉じる
    executeMutation(); // 親元でUpdate処理
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          以下の内容を登録してよろしいですか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ユーザー名: {editingUser.name}
            <br />
            電話番号: {editingUser.originalTextTelnum}
            <Typography component="span" sx={{ color: "gray" }}>
              ({editingUser.telnum})
            </Typography>
            <br />
            有効: {editingUser.available ? "ON" : "OFF"}
            <br />
            日中対応: {editingUser.dayTime ? "可" : "不可"}
            <br />
            夜間対応: {editingUser.nightTime ? "可" : "不可"}
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmCreateUser}>送信</Button>
          <Button onClick={onClose} autoFocus>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CreateUserBox = (props: {
  notifyMutation: () => void;
  dayUserList: user[];
  nightUserList: user[];
}): JSX.Element => {
  const { notifyMutation, dayUserList, nightUserList } = props;

  const [isProperName, setIsProperName] = useState(false); // 氏名が正しい文字列形式か
  const [hasAlreadyInputName, setHasAlreadyInputName] = useState(false); // 氏名フィールドを一度でも入力したか

  const [isProperTelnum, setIsProperTelnum] = useState(false); // 電話番号が正しい文字列形式か
  const [hasAlreadyInputTelnum, setHasAlreadyInputTelnum] = useState(false); // 電話番号フィールドを一度でも入力したか

  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false); // 送信ボタン有効化フラグ
  const [isSubmitting, setIsSubmitting] = useState(false); // 送信中フラグ
  const [editingUser, setEditingUser] = useState<editingUser>({
    id: "", // 使わないがuser型に合わせて持つ
    name: "",
    available: false,
    telnum: "",
    originalTextTelnum: "",
    dayTime: false,
    dayTimePriority: -1,
    nightTime: false,
    nightTimePriority: -1,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false); // 確認ダイアログ開いているかフラグ

  const executeCreateUser = async () => {
    setIsSubmitting(true);
    await CreateUserUsecase(
      editingUser.name,
      editingUser.telnum,
      editingUser.available,
      editingUser.dayTime,
      editingUser.dayTimePriority,
      editingUser.nightTime,
      editingUser.nightTimePriority
    )
      .then(() => {
        notifyMutation();
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChangeEditingUserName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasAlreadyInputName(true); // 一回でも入力したらフラグを立てる
    if (event.target.value === "") {
      // 1文字以上は必須
      setIsProperName(false);
    } else {
      setIsProperName(true);
    }
    setEditingUser({ ...editingUser, name: event.target.value });
  };

  const handleChangeEditingUserTelnum = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasAlreadyInputTelnum(true); // 一回でも入力したらフラグを立てる
    const telnumRegex = /^[0-9]+$/; // 全て数字であることをチェック
    if (!telnumRegex.exec(event.target.value)) {
      setIsProperTelnum(false);
    } else {
      setIsProperTelnum(true);
    }
    setEditingUser({
      ...editingUser,
      telnum: addCountryCode(event.target.value), // DBに書き込む文字列
      originalTextTelnum: event.target.value, // ユーザーが入力した文字列
    });
  };

  const handleChangeEditingUserAvailable = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditingUser({ ...editingUser, available: event.target.checked });
  };

  const handleChangeEditingUserDayTime = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditingUser({
      ...editingUser,
      dayTime: event.target.checked,
      dayTimePriority: event.target.checked
        ? dayUserList.length === 0 ? 1 : dayUserList[dayUserList.length - 1].dayTimePriority + 1 // チェックONなら優先度最低をセット
        : -1,
    });
  };

  const handleChangeEditingUserNightTime = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditingUser({
      ...editingUser,
      nightTime: event.target.checked,
      nightTimePriority: event.target.checked
        ? nightUserList.length === 0 ? 1 : nightUserList[nightUserList.length - 1].nightTimePriority + 1 // チェックONなら優先度最低をセット
        : -1,
    });
  };

  // 必須項目の適正フラグをトリガーに送信可能フラグをセット
  useEffect(() => {
    setIsReadyToSubmit(isProperName && isProperTelnum); // 全て適正なら送信可能→ボタンアクティブになる
  }, [isProperName, isProperTelnum]);

  return (
    <Paper sx={{ p: 4 }} elevation={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Paper
            elevation={2}
            sx={{
              backgroundColor: "#FFEEFF",
              p: 2,
              mb: 3,
              maxWidth: 240,
            }}
          >
            通知先の電話番号を登録します。
            <br />
            有効にした場合、各時間帯の優先順は、現時点での最後尾になります。
            <br />
            順番を変更したい場合は、「優先度変更」の画面から変更してください。
          </Paper>
        </Grid>
        {/* 入力フォーム */}
        <Grid item>
          <Grid container direction="column" alignItems="center" spacing={1}>
            {/* 名前 */}
            <Grid item>
              <TextField
                label="氏名"
                onChange={handleChangeEditingUserName}
                value={editingUser.name}
                required
                error={hasAlreadyInputName && !isProperName}
                helperText={
                  hasAlreadyInputName && !isProperName ? "氏名は必須です。" : ""
                }
              />
            </Grid>
            {/* 電話番号 */}
            <Grid item>
              <TextField
                label="電話番号"
                onChange={handleChangeEditingUserTelnum}
                value={editingUser.originalTextTelnum}
                required
                error={hasAlreadyInputTelnum && !isProperTelnum}
                helperText={
                  hasAlreadyInputTelnum && !isProperTelnum
                    ? "電話番号はすべて半角数字で入力してください。"
                    : ""
                }
                sx={{ maxWidth: 200 }}
              />
            </Grid>
            {/* 各フラグ */}
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeEditingUserAvailable}
                      checked={editingUser.available}
                    />
                  }
                  label="有効"
                />
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeEditingUserDayTime}
                      checked={editingUser.dayTime}
                    />
                  }
                  label="昼"
                />
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeEditingUserNightTime}
                      checked={editingUser.nightTime}
                    />
                  }
                  label="夜"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        {/* 横線 */}
        <Grid item>
          <Divider variant="middle" sx={{ width: 200 }} />
        </Grid>
        {/* 送信ボタン */}
        <Grid item>
          <SubmitButton
            disabled={!isReadyToSubmit}
            isProcessing={isSubmitting}
            handleClick={handleOpenDialog}
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={isDialogOpen}
        editingUser={editingUser}
        onClose={handleCloseDialog}
        executeMutation={executeCreateUser}
        notifyMutation={props.notifyMutation}
      />
    </Paper>
  );
};

export default CreateUserBox;

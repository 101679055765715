import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { user } from "../types/UserTypes";
import { UpdateUserUsecase } from "../usecases/UserUsecase";
import { FilterForm } from "./FilterForm";
import { ttlToReadableString } from "../utils/UtilFunctions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./UserList.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// スイッチ切替確認ダイアログ
const ConfirmationDialog = (props: {
  open: boolean;
  onClose: () => void;
  executeMutation: () => void; // 「確定」を押したときに親コンポーネントで実行してもらうコンフィグ更新処理
  notifyMutation: () => void;
}): JSX.Element => {
  const {  open, onClose, executeMutation } = props;

  const handleConfirmSwitch = () => {
    onClose(); // 確定押したらダイアログはすぐ閉じる
    executeMutation(); // 親元でUpdate処理
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            変更を確定します。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmSwitch}>確定</Button>
          <Button onClick={onClose} autoFocus>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserCard = (props: {
  user: user;
  notifyMutation: () => void;
}): JSX.Element => {

  return (
    <Card sx={{ minWidth: 275, marginBottom: 4 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          電話番号
        </Typography>
        <Typography variant="h5" component="div">
          0{props.user.telnum.slice(3)}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={props.user.available} />}
            label="有効"
          />
          <FormControlLabel
            control={<Switch checked={props.user.dayTime} />}
            label="昼"
          />
          <FormControlLabel
            control={<Switch checked={props.user.nightTime} />}
            label="夜"
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
};

const AccordionItem = (props: {
  userList: user[];
  setNeutralList: Dispatch<SetStateAction<user[]>>;
  setButtonDis: Dispatch<SetStateAction<boolean>>;
  notifyMutation: () => void;
}): JSX.Element => {
  const [users, setUsers] = useState<user[]>(props.userList);
  const [sortUsers, setSortUsers] = useState<user[]>(props.userList);
  const handleOnDragEnd = (result: any) => {
    const users = Array.from(sortUsers);
    const [reorderedItem] = users.splice(result.source.index, 1);
    if (result.destination) {
      users.splice(result.destination.index, 0, reorderedItem);
      setSortUsers(users);
      props.setNeutralList(users);
    }
    props.setButtonDis(false);
  };

  useEffect(() => {
    setUsers(props.userList);
    setSortUsers(props.userList);
  }, [props.userList]);
  return (
    <span>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="users">
          {(provided) => (
            <span {...provided.droppableProps} ref={provided.innerRef}>
              {sortUsers.map((user, idx) => {
                return (
                  <Draggable key={idx} draggableId={String(idx)} index={idx}>
                    {(provided) => (
                      <Accordion
                        key={idx}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          color: "white",
                          backgroundColor: user.available?"#2196f3":"#a9a9a9",
                          marginBottom: "1vh",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon className="expand" />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            {user.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <UserCard
                            user={user}
                            notifyMutation={props.notifyMutation}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Draggable>
                );
              })}
            </span>
          )}
        </Droppable>
      </DragDropContext>
    </span>
  );
};

const UserSort = (props: {
  userList: user[];
  dayUserList: user[];
  nightUserList: user[];
  notifyMutation: () => void;
}): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const [dayUsers, setDayUsers] = useState<user[]>(props.dayUserList);
  const [nightUsers, setNightUsers] = useState<user[]>(props.nightUserList);
  const [loading, setLoading] = useState(false);
  const [buttonDis, setButtonDis] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // 確認ダイアログ開いているかフラグ
  const explanationText = [
    <div>
      着信の優先度を変更したいユーザーをドラッグ＆ドロップで移動させてください。
    </div>,
    <div>
      変更後、「
      <Typography component="span" sx={{ fontWeight: "bold" }}>
        更新ボタンで確定
      </Typography>
      」してください。
    </div>,
    <div>
      確定せずに日中・夜間を切り替えると
      <Typography component="span" sx={{ fontWeight: "bold" }}>
        キャンセル扱い
      </Typography>
      になるため注意してください。
    </div>,
  ];
  async function confirm() {
    let X: "dayTimePriority" | "nightTimePriority";
    let Y: user[];
    setLoading(true);
    if (tabValue === 0) {
      X = "dayTimePriority";
      Y = dayUsers;
    } else {
      X = "nightTimePriority";
      Y = nightUsers;
    }
    for (let i = 0; i < Y.length; i++) {
      Y[i][X] = i + 1;
      await UpdateUserUsecase(
        Y[i].id,
        Y[i].name,
        Y[i].telnum,
        Y[i].available,
        Y[i].dayTime,
        Y[i].dayTimePriority,
        Y[i].nightTime,
        Y[i].nightTimePriority
      )
        .catch((err) => {
          alert(err);
        });
    }
    setLoading(false);
    setButtonDis(true);
    props.notifyMutation();
  }
  function handleClick() {
    setIsDialogOpen(true);
  }
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  function handleCancelClick() {
    setDayUsers(props.dayUserList);
    setNightUsers(props.nightUserList);
    setButtonDis(true);
    props.notifyMutation();
  }
  useEffect(() => {
    setDayUsers(props.dayUserList);
    setNightUsers(props.nightUserList);
    setButtonDis(true);
  }, [tabValue]);
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        alignItems: "center",
      }}
    >
      <Grid container direction="column" spacing={3} alignItems="center">
      <Grid item>
          {/* How to use */}
          <Paper
            elevation={2}
            sx={{
              backgroundColor: "#FFEEFF",
              p: 2,
              mb: 3,
              maxWidth: 240,
            }}
          >
            <List disablePadding>
              {explanationText.map((elem) => {
                return (
                  <ListItem disableGutters disablePadding>
                    <ListItemIcon sx={{ mr: -2 }}>
                      <PushPinIcon />
                    </ListItemIcon>
                    <ListItemText>{elem}</ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
        <Grid item>
          <LoadingButton
            onClick={handleClick}
            loading={loading}
            disabled={buttonDis}
            variant="contained"
            sx={{ alignItems: "center" }}
          >
            更新
          </LoadingButton>
          <Button
            variant="outlined"
            disabled={buttonDis}
            onClick={handleCancelClick}
          >
            キャンセル
          </Button>
        </Grid>
      </Grid>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="user-list"
        variant="fullWidth"
      >
        <Tab label="日中" disabled={loading} {...a11yProps(0)} />
        <Tab label="夜間" disabled={loading} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <AccordionItem
          userList={props.dayUserList}
          setNeutralList={setDayUsers}
          setButtonDis={setButtonDis}
          notifyMutation={props.notifyMutation}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AccordionItem
          userList={props.nightUserList}
          setNeutralList={setNightUsers}
          setButtonDis={setButtonDis}
          notifyMutation={props.notifyMutation}
        />
      </TabPanel>
      <ConfirmationDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          executeMutation={confirm}
          notifyMutation={props.notifyMutation}
        />
    </Box>
  );
};

export default UserSort;

import { Grid, TextField } from "@mui/material";
import { phrase } from "../types/PhraseTypes";

export const PhraseForm = (props: {
  editingPhrase: phrase;
  setEditingPhrase: (editingphrase: phrase) => void;
}): JSX.Element => {
  const { editingPhrase, setEditingPhrase } = props;

  // 入力
  const handleChangeEditingPhrase = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditingPhrase({ ...editingPhrase, phrase: event.target.value });
  };

  return (
    <div>
      <Grid container direction="column" alignItems="center" spacing={1}>
        {/* 入力 */}
        <Grid item>
          <TextField
            label="フィルターメッセージ"
            onChange={handleChangeEditingPhrase}
            value={editingPhrase.phrase}
          />
        </Grid>
      </Grid>
    </div>
  );
};

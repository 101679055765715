import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import { AuthState } from "@aws-amplify/ui-components";

import {
  GetConfigObjectUsecase,
  UpdateConfigUsecase,
} from "../usecases/ConfigUsecase";
import { useCognitoAuthContext } from "../context/CognitoAuthContext";

// スイッチ切替確認ダイアログ
const ConfirmationDialog = (props: {
  confirmingSwitch: boolean; // 切替後のスイッチの状態
  open: boolean;
  onClose: () => void;
  executeMutation: () => void; // 「確定」を押したときに親コンポーネントで実行してもらうコンフィグ更新処理
  notifyMutation: () => void;
}): JSX.Element => {
  const { confirmingSwitch, open, onClose, executeMutation } = props;

  const handleConfirmSwitch = () => {
    onClose(); // 確定押したらダイアログはすぐ閉じる
    executeMutation(); // 親元でUpdate処理
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            通知を{confirmingSwitch ? "ON" : "OFF"}
            にします。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmSwitch}>確定</Button>
          <Button onClick={onClose} autoFocus>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const NotificationSwitchBox = (): JSX.Element => {
  const [isDischarged, setIsDischarged] = useState(false); // 全止め／全通し
  const [isConnecting, setIsConnecting] = useState(false); // 全止め／全通しの通信中フラグ
  const [isDialogOpen, setIsDialogOpen] = useState(false); // 確認ダイアログ開いているかフラグ

  const [mutationCount, setMutationCount] = useState<number>(0);

  const { authState } = useCognitoAuthContext();

  const notifyMutation = () => {
    setMutationCount((prev: number) => {
      return prev + 1;
    });
  };

  const executeUpdate = () => {
    UpdateConfigUsecase("discharged", !isDischarged)
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        notifyMutation();
      });
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      Promise.all([
        // 一括設定の状態取得
        new Promise(() => {
          setIsConnecting(true);
          GetConfigObjectUsecase()
            .then((config) => {
              setIsDischarged(config.discharged);
            })
            .then(() => {
              setIsConnecting(false);
            })
            .catch((err) => {
              console.log(err);
              alert(err);
            });
        }),
      ]).catch((err) => {
        alert(err);
      });
    }
  }, [mutationCount, authState]);

  return (
    <div>
      <Paper elevation={4} sx={{ px: 4, py: 2 }}>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            {isDischarged ? (
              <NotificationsActiveIcon color="primary" fontSize="large" />
            ) : (
              <NotificationsOffOutlinedIcon color="disabled" fontSize="large" />
            )}
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {"アラートコール："}
              <Typography
                variant="h5"
                component="span"
                color={isDischarged ? "primary" : "gray"}
                sx={{ fontWeight: "bold" }}
              >
                {isDischarged ? "ON" : "OFF"}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            {isConnecting ? (
              <Button disabled={true}>通信中</Button>
            ) : isDischarged ? (
              <Button
                sx={{ backgroundColor: "gray" }}
                variant={"contained"}
                onClick={handleOpenDialog}
              >
                OFFにする
              </Button>
            ) : (
              <Button variant={"outlined"} onClick={handleOpenDialog}>
                ONにする
              </Button>
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
          confirmingSwitch={!isDischarged}
          open={isDialogOpen}
          onClose={handleCloseDialog}
          executeMutation={executeUpdate}
          notifyMutation={notifyMutation}
        />
      </Paper>
    </div>
  );
};

export default NotificationSwitchBox;

import { format } from "date-fns";

// TTL→人が読める形式に直す "yyyy/MM/dd HH:mm"
export const ttlToReadableString = (ttl: number | null): string => {
  if (ttl !== null) {
    const convertedDate = new Date(ttl * 1000);
    const dateString = format(convertedDate, "yyyy年MM月dd日 HH:mm");
    return dateString;
  } else {
    return "";
  }
};

// Date型→TTL
export const dateToTtl = (date: Date): number => {
  return Math.floor(date.getTime() / 1000);
};

// TTL→MUIのTextField type="date"のvalue用の値を作る
export const ttlToIsoDateString = (ttl: number): string => {
  const convertedDate = new Date(ttl * 1000);
  const dateString = format(convertedDate, "yyyy-MM-dd'T'HH:mm");
  return dateString;
};

// "+81"から始まる電話番号文字列を"0"始まりに変換する
export const removeCountryCode = (phoneNumber: string): string => {
  return "0" + phoneNumber.slice(3);
};

// "0"から始まる電話番号文字列に"+81"を付与する
export const addCountryCode = (phoneNumber: string): string => {
  return "+81" + phoneNumber.slice(1);
};

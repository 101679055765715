import axios from "axios";
import { format } from "date-fns";

interface getNextBusinessDayReturnType {
  nextBusinessDay: string;
}

// 渡された基準日から次の営業日を返す
export const getNextBusinessDay = async (date?: Date) => {
  try {
    const baseDate: string = format(date ? date : new Date(), "yyyy-MM-dd");
    const response = await axios.get<getNextBusinessDayReturnType>(
      "https://ssplf7zs70.execute-api.ap-northeast-1.amazonaws.com/demo/getnextbusinessday" +
        "?baseDate=" +
        baseDate,
      {
        headers: {
          "X-Api-Key": "E6GdKZNzYz7kab9P8AGY527ddfuGFUlx90pyZzV9",
        },
        responseType: "json",
      }
    );
    return new Date(response.data.nextBusinessDay);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

import {
  CreateUser,
  ListUsers,
  UpdateUser,
  DeleteUser,
} from "../services/UserService";
import { user } from "../types/UserTypes";

export const CreateUserUsecase = async (
  name: string,
  telnum: string,
  available: boolean,
  dayTime: boolean,
  dayTimePriority: number,
  nightTime: boolean,
  nightTimePriority: number
): Promise<user> => {
  try {
    if (name === "") {
      throw "ユーザー名が未入力です";
    }
    if (telnum === "") {
      throw "電話番号が未入力です";
    }
    const response = await CreateUser(
      name,
      telnum,
      available,
      dayTime,
      dayTimePriority,
      nightTime,
      nightTimePriority
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const ListUsersUsecase = async (): Promise<user[]> => {
  try {
    const Users = await ListUsers();
    return Users;
  } catch (err) {
    console.log(err);
    throw new Error("ユーザー一覧の取得中にエラーが発生しました");
  }
};

export const ListDayUsersUsecase = async (): Promise<user[]> => {
  try {
    const filter = {
      dayTime: {
        eq: true,
      },
    };
    const Users = await ListUsers(filter);
    return Users;
  } catch (err) {
    console.log(err);
    throw new Error("ユーザー一覧の取得中にエラーが発生しました");
  }
};

export const ListNightUsersUsecase = async (): Promise<user[]> => {
  try {
    const filter = {
      nightTime: {
        eq: true,
      },
    };
    const Users = await ListUsers(filter);
    return Users;
  } catch (err) {
    console.log(err);
    throw new Error("ユーザー一覧の取得中にエラーが発生しました");
  }
};

export const UpdateUserUsecase = async (
  id: string,
  name: string | null,
  telnum: string | null,
  available: boolean | null,
  dayTime: boolean | null,
  dayTimePriority: number | null,
  nightTime: boolean | null,
  nightTimePriority: number | null
): Promise<user> => {
  try {
    if (id === "") {
      throw "IDが未入力です";
    }
    const response = await UpdateUser(
      id,
      name,
      telnum,
      available,
      dayTime,
      dayTimePriority,
      nightTime,
      nightTimePriority
    );
    return response;
  } catch (err) {
    console.log(err);
    throw new Error("ユーザー更新中にエラーが発生しました");
  }
};

export const DeleteUserUsecase = async (id: string): Promise<void> => {
  try {
    const user = await DeleteUser(id);
    return user;
  } catch (err) {
    console.log(err);
    throw new Error("ユーザーの削除に失敗しました");
  }
};

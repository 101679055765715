import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { format, addHours } from "date-fns";
import { useState } from "react";

import { usePhraseContext } from "../context/PhraseContext";
import { filter } from "../types/FilterTypes";
import { getNextBusinessDay } from "../utils/LambdaAPI";
import { dateToTtl, ttlToIsoDateString } from "../utils/UtilFunctions";

export const FilterForm = (props: {
  editingFilter: filter;
  setEditingFilter: (editingfilter: filter) => void;
}): JSX.Element => {
  const { phraseList, reloadPhraseList, isLoadingPhraseList } =
    usePhraseContext();

  const { editingFilter, setEditingFilter } = props;
  const [isLoadingNextBusinessDay, setIsLoadingNextBusinessDay] =
    useState<boolean>(false);

  // 定型句再読み込み
  const handleClickUpdatePhraseList = () => {
    reloadPhraseList();
  };

  // 定型句リストから選択
  const handleChangeSelectedPhrase = (event: any, newValue: string | null) => {
    setEditingFilter({ ...editingFilter, phrase: newValue ? newValue : "" });
  };

  // 手入力
  const handleChangeManualText = (event: any, newInputValue: string | null) => {
    setEditingFilter({
      ...editingFilter,
      phrase: newInputValue ? newInputValue : "",
    });
  };

  // 停止期限
  const handleChangeTTL = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditingFilter({
      ...editingFilter,
      TTL: dateToTtl(new Date(event.target.value)),
    });
  };

  // 一時間後
  const handleClickUntilNextOneHour = () => {
    setEditingFilter({
      ...editingFilter,
      TTL: dateToTtl(addHours(new Date(), 1)),
    });
  };
  // 翌営業日
  const handleClickUntilNextBusinessDay = () => {
    setIsLoadingNextBusinessDay(true);
    getNextBusinessDay(new Date())
      .then((res) => {
        setEditingFilter({
          ...editingFilter,
          TTL: dateToTtl(new Date(format(res, "yyyy/MM/dd 08:40:00"))),
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      })
      .finally(() => {
        setIsLoadingNextBusinessDay(false);
      });
  };

  return (
    <div>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Autocomplete
                disablePortal
                // disableClearable
                forcePopupIcon
                freeSolo
                id="filter-message-combo-box"
                options={phraseList.map((phrase) => {
                  return phrase.phrase;
                })}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label="メッセージ" />
                )}
                onChange={handleChangeSelectedPhrase}
                value={editingFilter.phrase}
                defaultValue={phraseList[0] ? phraseList[0].phrase : ""}
                onInputChange={handleChangeManualText}
                inputValue={editingFilter.phrase}
              />
            </Grid>
            {/* 定型句再読み込みボタン */}
            <Grid item>
              <IconButton
                aria-label="updatePhraseList"
                color="primary"
                onClick={
                  isLoadingPhraseList ? handleClickUpdatePhraseList : () => {} // 読み込み中は重複処理させない
                }
                sx={
                  isLoadingPhraseList
                    ? { animation: "spin 1s linear infinite" }
                    : {}
                }
              >
                <LoopIcon />
                <style>{`
                      @keyframes spin {
                        0% { transform: rotate(360deg); }
                        100% { transform: rotate(0deg); }
                  }`}</style>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        {/* 日付入力 */}
        <Grid item>
          <TextField
            type="datetime-local"
            value={
              editingFilter.TTL
                ? ttlToIsoDateString(editingFilter.TTL)
                : format(new Date(), "yyyy-MM-dd'T'HH:mm")
            }
            onChange={handleChangeTTL}
          />
        </Grid>

        {/* 一時間後ボタン */}
        <Grid item>
          <Button variant="contained" onClick={handleClickUntilNextOneHour}>
            今から1時間後
          </Button>
        </Grid>
        {/* 次営業日ボタン（読み込み中は無効化） */}
        <Grid item>
          {isLoadingNextBusinessDay ? (
            <Button variant="outlined" disabled={true}>
              取得中
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleClickUntilNextBusinessDay}
            >
              次営業日まで
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useState } from "react";
import { user } from "../types/UserTypes";
import { UpdateUserUsecase, DeleteUserUsecase } from "../usecases/UserUsecase";
import { FilterForm } from "./FilterForm";
import {
  ttlToReadableString,
  addCountryCode,
  removeCountryCode,
} from "../utils/UtilFunctions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./UserList.css";

interface editParams {
  telnum: string;
  available: boolean;
  dayTime: boolean;
  dayTimePriority: number;
  nightTime: boolean;
  nightTimePriority: number;
}

interface PriCheck {
  dayTimePriority: number;
  nightTimePriority: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// 編集ダイアログ
const EditDialog = (props: {
  user: user;
  userList: user[];
  open: boolean;
  onClose: () => void;
  notifyMutation: () => void;
}): JSX.Element => {
  const [editingUser, setEditingUser] = useState<user>({
    ...props.user,
  });
  const [newParams, setNewParams] = useState<editParams>({
    telnum: removeCountryCode(props.user.telnum),
    available: props.user.available,
    dayTime: props.user.dayTime,
    dayTimePriority: props.user.dayTimePriority,
    nightTime: props.user.nightTime,
    nightTimePriority: props.user.nightTimePriority,
  });
  const {
    telnum,
    available,
    dayTime,
    dayTimePriority,
    nightTime,
    nightTimePriority,
  } = newParams;

  const handleClose = () => {
    // 内部の値を初期化
    setEditingUser({ ...props.user });
    setNewParams({
      telnum: removeCountryCode(props.user.telnum),
      available: props.user.available,
      dayTime: props.user.dayTime,
      dayTimePriority: props.user.dayTimePriority,
      nightTime: props.user.nightTime,
      nightTimePriority: props.user.nightTimePriority,
    });
    // 渡されたクローズ処理を実行
    props.onClose();
  };
  const handleEditUpdateConfirm = () => {
    UpdateUserUsecase(
      editingUser.id,
      editingUser.name,
      // "+81" + telnum.slice(1),
      addCountryCode(telnum),
      available,
      dayTime,
      dayTimePriority,
      nightTime,
      nightTimePriority
    )
      .then(() => {
        props.notifyMutation();
        props.onClose();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const checkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewParams({
      ...newParams,
      [event.target.name]: event.target.checked,
    });
  };
  const checkPriChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempPri;
    const column = `${event.target.name}Priority`;
    let priParam: keyof typeof props.user;
    if (column in props.user) {
      priParam = column as keyof typeof props.user;
      let sort = props.userList.sort((x, y) => {
        return x[priParam] < y[priParam] ? -1 : 1;
      });
      if (event.target.checked && props.userList.length !== 0 ) {
        tempPri = sort[props.userList.length - 1][priParam];
      } else {
        tempPri = -2;
      }
      if (typeof tempPri == "number") {
        setNewParams({
          ...newParams,
          [event.target.name]: event.target.checked,
          [`${event.target.name}Priority`]: tempPri + 1,
        });
      } else {
        alert(`優先度が数値ではありません\n${tempPri}`);
      }
    } else {
      alert(`優先度設定でエラーが発生しました\n${column}`);
    }
  };

  const handleChangeTelnumText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewParams({ ...newParams, telnum: event.target.value });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <DialogTitle id="alert-dialog-title">編集</DialogTitle>
          <DialogContent>
            <br />
            <TextField
              label="電話番号"
              onChange={handleChangeTelnumText}
              value={telnum}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={available}
                    onChange={checkChange}
                    name="available"
                  />
                }
                label="有効"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={dayTime}
                    onChange={checkPriChange}
                    name="dayTime"
                  />
                }
                label="昼"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={nightTime}
                    onChange={checkPriChange}
                    name="nightTime"
                  />
                }
                label="夜"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditUpdateConfirm}>確定</Button>
            <Button onClick={handleClose} autoFocus>
              キャンセル
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

// 削除ダイアログ
const DeleteDialog = (props: {
  user: user;
  open: boolean;
  onClose: () => void;
  notifyMutation: () => void;
}): JSX.Element => {
  const handleDeleteContinue = () => {
    DeleteUserUsecase(props.user.id)
      .then((res) => {
        props.onClose();
      })
      .then(() => {
        props.notifyMutation();
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          削除してよろしいですか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            氏名: {props.user.name}
            <br />
            電話番号:
            {props.user.telnum}
            <br />
            有効:
            {props.user.available ? "ON" : "OFF"}
            <br />
            日中:
            {props.user.dayTime ? "可" : "不可"}
            <br />
            夜間:
            {props.user.nightTime ? "可" : "不可"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteContinue}>削除</Button>
          <Button onClick={props.onClose} autoFocus>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const UserCard = (props: {
  user: user;
  userList: user[];
  notifyMutation: () => void;
}): JSX.Element => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false); // 編集ダイアログ開いているかフラグ
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // 削除ダイアログ開いているかフラグ

  // [編集]クリック時
  const onClickEdit = () => {
    setIsEditDialogOpen(true);
  };

  // 編集ダイアログ閉じる
  const handleCloseEdit = () => {
    setIsEditDialogOpen(false);
  };

  // [削除]クリック時
  const onClickDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  // 削除ダイアログ閉じる
  const handleCloseDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Card sx={{ minWidth: 275, marginBottom: 4 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          電話番号
        </Typography>
        <Typography variant="h5" component="div">
          {removeCountryCode(props.user.telnum)}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={props.user.available} />}
            label="有効"
          />
          <FormControlLabel
            control={<Switch checked={props.user.dayTime} />}
            label="昼"
          />
          <FormControlLabel
            control={<Switch checked={props.user.nightTime} />}
            label="夜"
          />
        </FormGroup>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onClickEdit}>
          編集
        </Button>
        <EditDialog
          user={props.user}
          userList={props.userList}
          open={isEditDialogOpen}
          onClose={handleCloseEdit}
          notifyMutation={props.notifyMutation}
        />
        <Button size="small" onClick={onClickDelete}>
          削除
        </Button>
        <DeleteDialog
          user={props.user}
          open={isDeleteDialogOpen}
          onClose={handleCloseDelete}
          notifyMutation={props.notifyMutation}
        />
      </CardActions>
    </Card>
  );
};

const AccordionItem = (props: {
  userList: user[];
  notifyMutation: () => void;
}): JSX.Element => {
  return (
    <span>
      {props.userList.map((user, idx) => {
        return (
          <Accordion
            key={idx}
            sx={{
              color: "white",
              backgroundColor: user.available?"#2196f3":"#a9a9a9",
              marginBottom: "1vh",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="expand" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {user.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UserCard
                user={user}
                userList={props.userList}
                notifyMutation={props.notifyMutation}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </span>
  );
};

const UserList = (props: {
  userList: user[];
  dayUserList: user[];
  nightUserList: user[];
  notifyMutation: () => void;
}): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const explanationText = [
    <div>
      情報を変更したいユーザーを選択して展開してください。
    </div>,
    <div>
      展開後、「
      <Typography component="span" sx={{ fontWeight: "bold" }}>
        編集
      </Typography>
      」を押してください。
    </div>,
    <div>
      休暇等で一時的に着信を拒否する場合は「
      <Typography component="span" sx={{ fontWeight: "bold" }}>
        有効をOFF
      </Typography>
      」にしてください。
    </div>,
    <div>
      ユーザーを削除する場合は展開後、「
      <Typography component="span" sx={{ fontWeight: "bold" }}>
        削除
      </Typography>
      」を押してください。
    </div>,
  ];
  return (
    <span>
  <Grid container direction="column" spacing={3} alignItems="center">
  <Grid item>
      {/* How to use */}
      <Paper
        elevation={2}
        sx={{
          backgroundColor: "#FFEEFF",
          p: 2,
          mb: 3,
          maxWidth: 240,
        }}
      >
        <List disablePadding>
          {explanationText.map((elem) => {
            return (
              <ListItem disableGutters disablePadding>
                <ListItemIcon sx={{ mr: -2 }}>
                  <PushPinIcon />
                </ListItemIcon>
                <ListItemText>{elem}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Grid>
    </Grid>
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="user-list"
        variant="fullWidth"
      >
        <Tab label="全員" {...a11yProps(0)} />
        <Tab label="日中" {...a11yProps(1)} />
        <Tab label="夜間" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Typography sx={{textAlign:"right"}}>※五十音順</Typography>
        <AccordionItem
          userList={props.userList}
          notifyMutation={props.notifyMutation}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Typography sx={{textAlign:"right"}}>※日中優先度順</Typography>
        <AccordionItem
          userList={props.dayUserList}
          notifyMutation={props.notifyMutation}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Typography sx={{textAlign:"right"}}>※夜間優先度順</Typography>
        <AccordionItem
          userList={props.nightUserList}
          notifyMutation={props.notifyMutation}
        />
      </TabPanel>
    </Box>
    </span>
  );
};

export default UserList;

import { useEffect, useState } from "react";
import { AuthState } from "@aws-amplify/ui-components";

import UserList from "./UserList";
import UserSort from "./UserSort";
import {
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";

import { useCognitoAuthContext } from "../context/CognitoAuthContext";

import { user } from "../types/UserTypes";
import {
  CreateUserUsecase,
  ListUsersUsecase,
  ListDayUsersUsecase,
  ListNightUsersUsecase,
} from "../usecases/UserUsecase";
import CreateUserBox from "./CreateUserBox";

const UserPage = (): JSX.Element => {
  const { module } = useParams<{ module: string }>();
  const [userList, setUserList] = useState<user[]>([]); // ユーザーリスト
  const [dayUserList, setDayUserList] = useState<user[]>([]); // 日中ユーザーリスト
  const [nightUserList, setNightUserList] = useState<user[]>([]); // 夜間ユーザーリスト
  const [mutationCount, setMutationCount] = useState<number>(0);
  const [cont, setCont] = useState<string>();

  const { authState } = useCognitoAuthContext();

  const notifyMutation = async () => {
    setMutationCount((prev: number) => {
      return prev + 1;
    });
  };

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      (async () => {
        await ListUsersUsecase().then((res) => {
          let sort = res.sort((x, y) => {
            return x.name < y.name ? -1 : 1;
          });
          setUserList(sort);
        });
        await ListDayUsersUsecase().then((res) => {
          let sort = res.sort((x, y) => {
            return x.dayTimePriority < y.dayTimePriority ? -1 : 1;
          });
          setDayUserList(sort);
        });
        await ListNightUsersUsecase().then((res) => {
          let sort = res.sort((x, y) => {
            return x.nightTimePriority < y.nightTimePriority ? -1 : 1;
          });
          setNightUserList(sort);
        });
      })();
    }
    setCont(module);
  }, [mutationCount, module]);

  return (
    <div>
      <Box sx={{ my: 2 }}>
        <Grid
          container
          direction="column"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4">連絡先設定</Typography>
          </Grid>
          <Grid item>
            <Box sx={{ px: 4, pb: 4 }}>
              <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  {cont === "create" ? (
                    <span>
                      <Grid item sx={{ textAlign: "center", mb: 2 }}>
                        <Typography variant="h5">連絡先登録</Typography>
                      </Grid>
                      <CreateUserBox
                        notifyMutation={notifyMutation}
                        dayUserList={dayUserList}
                        nightUserList={nightUserList}
                      />
                    </span>
                  ) : cont === "list" ? (
                    <span>
                      <Grid item sx={{ textAlign: "center", mb: 2 }}>
                        <Typography variant="h5">連絡先一覧</Typography>
                      </Grid>
                      <Grid item>
                        <UserList
                          userList={userList}
                          dayUserList={dayUserList}
                          nightUserList={nightUserList}
                          notifyMutation={notifyMutation}
                        />
                      </Grid>
                    </span>
                  ) : cont === "sort" ? (
                    <span>
                      <Grid item sx={{ textAlign: "center", mb: 2 }}>
                        <Typography variant="h5">優先度変更</Typography>
                      </Grid>
                      <Grid item>
                        <UserSort
                          userList={userList}
                          dayUserList={dayUserList}
                          nightUserList={nightUserList}
                          notifyMutation={notifyMutation}
                        />
                      </Grid>
                    </span>
                  ) : (
                    <p>
                      読み込みに失敗しました。
                      <br />
                      ホームに移動してください。
                    </p>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UserPage;

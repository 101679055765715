import { API, graphqlOperation } from "aws-amplify";
import {
  CreateConfigInput,
  CreateConfigMutation,
  ListConfigsQuery,
  ListConfigsQueryVariables,
  UpdateConfigInput,
  UpdateConfigMutation,
  DeleteConfigInput,
  DeleteConfigMutation,
} from "../API";
import { createConfig, updateConfig, deleteConfig } from "../graphql/mutations";
import { listConfigs } from "../graphql/queries";
import { config } from "../types/ConfigTypes";

export const CreateConfig = async (
  key: string,
  value: any
): Promise<config> => {
  try {
    const params: CreateConfigInput = {
      key: key,
      value: JSON.stringify(value),
    };
    const response = (await API.graphql(
      graphqlOperation(createConfig, { input: params })
    )) as { data: CreateConfigMutation };
    if (response.data && response.data.createConfig) {
      const datas: config = {
        key: response.data.createConfig.key,
        value: response.data.createConfig.value,
      };
      return datas;
    } else {
      throw new Error("return noData");
    }
  } catch (err) {
    console.log(err);
    throw new Error("コンフィグ登録中にエラーが発生しました");
  }
};

export const ListConfigs = async (): Promise<config[]> => {
  let nextToken: string | null = null;
  const datas: config[] = [];
  try {
    //未取得データがある場合は全てのデータを取得するまでループ
    do {
      const params: ListConfigsQueryVariables = {
        limit: 100,
        nextToken: nextToken,
      };
      const response = (await API.graphql(
        graphqlOperation(listConfigs, params)
      )) as {
        data: ListConfigsQuery;
      };
      if (response.data.listConfigs && response.data.listConfigs.items) {
        response.data.listConfigs.items.map((item) => {
          if (item) {
            datas.push({
              key: item.key,
              value: JSON.parse(item.value),
            } as config);
          }
        });
        nextToken = response.data.listConfigs.nextToken;
      }
    } while (nextToken !== null);
  } catch (err) {
    console.log(err);
    throw err;
  }
  return datas;
};

export const UpdateConfig = async (
  key: string,
  value: any
): Promise<config> => {
  try {
    const params: UpdateConfigInput = {
      key: key,
      value: JSON.stringify(value),
    };
    const response = (await API.graphql(
      graphqlOperation(updateConfig, { input: params })
    )) as { data: UpdateConfigMutation };
    if (response.data.updateConfig) {
      const data: config = {
        key: response.data.updateConfig.key,
        value: response.data.updateConfig.value,
      };
      return data;
    } else {
      throw new Error("no Data");
    }
  } catch (err) {
    throw new Error("コンフィグ更新中にエラーが発生しました");
  }
};

export const DeleteConfig = async (key: string): Promise<void> => {
  try {
    const params: DeleteConfigInput = {
      key: key,
    };
    const response = (await API.graphql(
      graphqlOperation(deleteConfig, { input: params })
    )) as { data: DeleteConfigMutation };

    if (!response.data.deleteConfig) {
      throw new Error("no Data");
    }
  } catch (err) {
    throw new Error("フィルターメッセージ削除中にエラーが発生しました");
  }
};

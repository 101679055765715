import React, { useState, createContext, useContext, useEffect } from "react";
import { AuthState } from "@aws-amplify/ui-components";

import { phrase } from "../types/PhraseTypes";
import { ListPhrasesUsecase } from "../usecases/PhraseUsecase";
import { useCognitoAuthContext } from "./CognitoAuthContext";

// 定型句リストをグローバルに管理するコンテクスト

interface ContextInterface {
  phraseList: phrase[]; // 定型句リスト
  reloadPhraseList: () => void; // 呼び元で更新・削除したときに再読み込みさせるメソッド
  isLoadingPhraseList: boolean; // 読み込み中フラグ ローディングボタンの表示分岐などで使ってください
}

const PhraseContext = createContext<ContextInterface | undefined>(undefined);
export const usePhraseContext = () => {
  const context = useContext(PhraseContext);

  if (context === undefined) {
    throw new Error(
      "usePhraseContext must be used within a PhraseContextProvider."
    );
  }
  return context;
};

export const PhraseContextProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const [phraseList, setPhraseList] = useState<phrase[]>([]);
  const [isLoadingPhraseList, setIsLoadingPhraseList] =
    useState<boolean>(false);

  const [mutationCount, setMutationCount] = useState<number>(0);
  const reloadPhraseList = () => {
    setMutationCount((prev) => {
      return prev + 1;
    });
  };

  const { authState } = useCognitoAuthContext();

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      setIsLoadingPhraseList(true);
      // 定型句リスト取得
      ListPhrasesUsecase()
        .then((res) => {
          setPhraseList(
            res.sort((left, right) => {
              if (left.phrase > right.phrase) {
                return 1;
              } else if (left.phrase < right.phrase) {
                return -1;
              } else {
                return 0;
              }
            })
          );
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setIsLoadingPhraseList(false);
        });
    }
  }, [mutationCount, authState]);

  return (
    <PhraseContext.Provider
      value={{ phraseList, reloadPhraseList, isLoadingPhraseList }}
    >
      {props.children}
    </PhraseContext.Provider>
  );
};

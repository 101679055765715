import { API, graphqlOperation } from "aws-amplify";
import {
  CreateFilterInput,
  CreateFilterMutation,
  ListFiltersQuery,
  ListFiltersQueryVariables,
  UpdateFilterInput,
  UpdateFilterMutation,
  DeleteFilterInput,
  DeleteFilterMutation,
} from "../API";
import { createFilter, updateFilter, deleteFilter } from "../graphql/mutations";
import { listFilters } from "../graphql/queries";
import { filter } from "../types/FilterTypes";

export const CreateFilter = async (
  phrase: string,
  TTL: number | null
): Promise<filter> => {
  try {
    const params: CreateFilterInput = {
      phrase: phrase,
      TTL: TTL,
    };
    const response = (await API.graphql(
      graphqlOperation(createFilter, { input: params })
    )) as { data: CreateFilterMutation };
    if (response.data && response.data.createFilter) {
      const datas: filter = {
        id: response.data.createFilter.id,
        phrase: response.data.createFilter.phrase,
        TTL: response.data.createFilter.TTL,
      };
      return datas;
    } else {
      throw new Error("return noData");
    }
  } catch (err) {
    console.log(err);
    throw new Error("フィルターメッセージ登録中にエラーが発生しました");
  }
};

export const ListFilters = async (): Promise<filter[]> => {
  let nextToken: string | null = null;
  const datas: filter[] = [];
  try {
    //未取得データがある場合は全てのデータを取得するまでループ
    do {
      const params: ListFiltersQueryVariables = {
        limit: 100,
        nextToken: nextToken,
      };
      const response = (await API.graphql(
        graphqlOperation(listFilters, params)
      )) as {
        data: ListFiltersQuery;
      };
      if (response.data.listFilters && response.data.listFilters.items) {
        response.data.listFilters.items.map((item) => {
          if (item) {
            datas.push({
              id: item.id,
              phrase: item.phrase,
              TTL: item.TTL,
            } as filter);
          }
        });
        nextToken = response.data.listFilters.nextToken;
      }
    } while (nextToken !== null);
  } catch (err) {
    console.log(err);
    throw new Error("フィルターメッセージ一覧取得中にエラーが発生しました。");
  }
  return datas;
};

export const UpdateFilter = async (
  id: string,
  phrase: string,
  TTL: number | null
): Promise<filter> => {
  try {
    const params: UpdateFilterInput = {
      id: id,
      phrase: phrase,
      TTL: TTL,
    };
    const response = (await API.graphql(
      graphqlOperation(updateFilter, { input: params })
    )) as { data: UpdateFilterMutation };
    if (response.data.updateFilter) {
      const data: filter = {
        id: response.data.updateFilter.id,
        phrase: response.data.updateFilter.phrase,
        TTL: response.data.updateFilter.TTL,
      };
      return data;
    } else {
      throw new Error("no Data");
    }
  } catch (err) {
    console.log(err);
    throw new Error("フィルターメッセージ更新中にエラーが発生しました");
  }
};

export const DeleteFilter = async (id: string): Promise<void> => {
  try {
    const params: DeleteFilterInput = {
      id: id,
    };
    const response = (await API.graphql(
      graphqlOperation(deleteFilter, { input: params })
    )) as { data: DeleteFilterMutation };

    if (!response.data.deleteFilter) {
      throw new Error("no Data");
    }
  } catch (err) {
    console.log(err);
    throw new Error("フィルターメッセージ削除中にエラーが発生しました");
  }
};
